import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';

// ayudante para manejar la base de datos de la aplicación
// llaves y configuración de FB
const firebaseConfig = {

    apiKey: "AIzaSyArj4qWDx501ID4TDaSxCsqMTt0hmt1qVI",
    authDomain: "queba-4051e.firebaseapp.com",
    databaseURL: "https://queba-4051e-default-rtdb.firebaseio.com",
    projectId: "queba-4051e",
    storageBucket: "queba-4051e.appspot.com",
    messagingSenderId: "1032032174804",
    appId: "1:1032032174804:web:2da7fe49e20f515d85b141",
    measurementId: "G-9VGYB7Q9JG"

};
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore()
const storage = getStorage(firebaseApp)
const auth = getAuth();
export { auth, db, firebaseApp, storage };

