// metodo para convertir los campos del usuario en nombres de la
//base de datos
const FormatData = (ref) => {
    ref = ref.replace(/ /g, "_");
    ref = ref.replace(/\//g, "-");
    ref = ref.replace(/á/g, "a");
    ref = ref.replace(/é/g, "e");
    ref = ref.replace(/í/g, "i");
    ref = ref.replace(/ó/g, "o");
    ref = ref.replace(/ú/g, "u");
    return ref
}

//convertir datos de la db a datos que el usuario pueda ver
const UnFormatData = (ref) => {
    ref = ref.replace(/_/g, " ");
    ref = ref.replace(/-/g, "/");
    ref = ref.replace("alimentacion", "alimentación");
    ref = ref.replace(/iluminacion/, "iluminación");
    ref = ref.replace(/energia/, "energía");
    ref = ref.replace(/educacion/, "educación");
    ref = ref.replace(/innovacion/, "innovación");
    ref = ref.replace(/gestion/, "gestión");
    ref = ref.replace(/inclusion/, "inclusión");

    return ref
}
export { FormatData ,UnFormatData }
