import React from "react";
import { Col, Row } from "react-bootstrap";

//se muestra en /admin
//sirve para mostrar cada uno de los productos de cada una de las ordenes
const ProductoCard = (props) => {

    return (
        <>
            <div className="shadow rounded mb-4" >
                <div className="text-center bg-aqua text-white rounded-top" >
                    <p className="p-2"> {props.item.data().name}</p>
                </div>
                <Row className="p-2 d-flex justify-content-center align-items-center">
                    <Col>
                        <img style={{ height: "80px" }} className="img-fluid rounded-circle" src={props.item.data().foto} alt="..." />
                    </Col>

                    <Col className="">
                        {props.item.data().cantidad} unidades
                    </Col>

                    <Col>
                        ${props.item.data().total}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ProductoCard