import React, { useContext } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { StatusCompra } from "../../user/StatusCompra";
import UserContext from "../../user/UserContext";
import CardView5 from "./CardView5";

// Quinta vista de la pantalla /canasta
// le muestra al lider que su Productos estan en camino
// es la quinta face de la compra

const CanastaView5 = () => {

    const { carrito, user } = useContext(UserContext)

    return (
        <>
            {carrito.data.status === StatusCompra.enCamino &&
                <>
                    <Container className="text-center mt-5">
                        <h2>En Camino</h2>
                    </Container>
                    <Container className="w-75 my-5 text-center">

                        <Alert variant="info" className=" py-5">
                            <h4>¡Tus productos van en camino!
                            </h4>
                            <br />
                            <p> Nos complace anunciar que tus productos llegaran pronto, nos pondremos en contacto cuando estemos en tu domicilio con tus productos
                            </p>
                        </Alert>
                    </Container>
                </>
            }
            {carrito.data.status === StatusCompra.finalizado &&
                <>
                    <Container className="text-center mt-5">
                        <h2>Entrega finalizada</h2>
                    </Container>
                </>
            }
            <Container className="w-75 my-5 text-center" >
                <p>Dirección de entrega:<span>{user.data.address}</span></p>
                {carrito.productos.map((item, idx) => (

                    <CardView5 key={"canastaV" + idx} item={item} />
                ))}

                <h5>Resumen</h5>

                <hr className="divider" />
                <Row xs={1} md={2} className="">

                    <Col className="text-start">
                        <p>Subtotal: </p>
                    </Col>
                    <Col className="text-end">
                        <p>${carrito.total}</p>
                    </Col>
                </Row>
                <hr className="divider" />

                <Row xs={1} md={2} className="">

                    <Col className="text-start">
                        <p>Transporte: </p>
                    </Col>
                    <Col className="text-end">
                        <p>${parseFloat(user.data.costo_envio).toFixed(2)}</p>
                    </Col>
                </Row>
                <hr className="divider" />
                {carrito.data.costo_peso_extra > 0 && <> <Row xs={1} md={2} className="">

                    <Col className="text-start">
                        <p>Costo por peso extra: </p>
                    </Col>
                    <Col className="text-end">
                        <p>${parseFloat(carrito.data.costo_peso_extra).toFixed(2)}</p>
                    </Col>
                </Row>
                <hr className="divider" /></>}
                <Row xs={1} md={2} className="">

                    <Col className="text-start">
                        <p>Total: </p>
                    </Col>
                    <Col className="text-end">
                        <p>${(parseFloat(carrito.data.monto)).toFixed(2)}</p>
                    </Col>
                </Row>
            </Container>
            <Container className="w-75 my-5 text-center">

                <Alert variant="info" className=" py-5">
                    <p>Gracias por confiar en nosotros, te invitamos a revisar el resto de nuestras <Link to="/redes"> redes</Link></p>

                </Alert>
            </Container>
        </>
    )
}

export default CanastaView5