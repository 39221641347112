import { collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { db, storage } from "../../firebase/FirebaseConfig";
import { v4 as uuidv4 } from 'uuid';

// UI del crud para el administrador que controla el blog
// se muestra en /admin

const AddBlog = () => {

    //datos recibidos de la base de datos 
    const [data, setData] = useState([])
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);

    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        const querySnapshot = await getDocs(collection(db, "blog"));
        setData(querySnapshot.docs)

    }

    const [itemSeleccionado, setitemSeleccionado] = useState({
        date: "",
        title: "",
        content: "",
        foto: ""
    })
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);
        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }
    const [imagen, setImagen] = useState(undefined)
    const HandleImageUpload = (archivos) => {
        setImagen(archivos[0])

    }
    const AddBLog2 = async (ref, temID) => {
        setImagen(undefined)
        await setDoc(doc(db, "blog", temID),
            {
                date: itemSeleccionado.date,
                title: itemSeleccionado.title,
                content: itemSeleccionado.content.replace(/\n/g,"<br/>"),
                foto: ref
            });
        GetItems()
        OpenCLoseModalInsertar()
        alert("¡Entrada creada correctamente!");
    }
    const Addblog = () => {
        if (itemSeleccionado.title !== "" && itemSeleccionado.date !== "" && itemSeleccionado.content !== "" && imagen !== undefined) {
            const temID = uuidv4();
            const storageRef = ref(storage, 'blog/' + temID);

            ////////////////////////
            //subir foto del blog //
            ////////////////////////
            uploadBytes(storageRef, imagen).then((snapshot) => {
                getDownloadURL(storageRef).then(ref => {
                    AddBLog2(ref, temID)
                    OpenCLoseModalInsertar()
                }).catch((error) => {
                    const errorMessage = error.message;
                    alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                    // ..
                });
            }).catch((error) => {
                const errorMessage = error.message;
                alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                // ..
            });
        } else {
            alert("Por favor rellena todos los campos")
        }
    }
    const DeleteBlog = async () => {
        await deleteDoc(doc(db, "blog", editItemSeleccionado.id));

        const desertRef = ref(storage, 'blog/' + editItemSeleccionado.id);

        deleteObject(desertRef).then(() => {
            GetItems()
            OpenCLoseModalEliminar()
            alert("¡Producto eliminado correctamente!")
        }).catch((error) => {
            alert("Ocurrió un error al eliminar \n inténtalo más tarde")
        });
    }

    const UpdateBlog2 = async (id, ref) => {
        setImagen(undefined)
        await setDoc(doc(db, "blog", id),
            {
                date: itemSeleccionado.date,
                title: itemSeleccionado.title,
                content: itemSeleccionado.content.replace(/\n/g,"<br/>"),
                foto: ref
            });
        GetItems()
        OpenCLoseModalEditar()
        alert("¡Entrada editada correctamente!");
    }
    const UpdateBlog = () => {
        if (itemSeleccionado.title !== "" && itemSeleccionado.date !== "" && itemSeleccionado.content !== "") {
            if (imagen !== undefined) {
                const storageRef = ref(storage, 'blog/' + editItemSeleccionado.id);
                // 'file' comes from the Blob or File API
                uploadBytes(storageRef, imagen).then((snapshot) => {
                    getDownloadURL(storageRef).then(ref => {

                        UpdateBlog2(editItemSeleccionado.id, ref)

                    }).catch((error) => {
                        const errorMessage = error.message;
                        alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                        // ..
                    });
                })
            } else {
                UpdateBlog2(editItemSeleccionado.id, itemSeleccionado.foto)
            }
        }
    }
    return (
        <>
            <div className="container text-center my-5 ">
                <h1>Blog<button className="btn text-white bg-indigo m-5" onClick={() => OpenCLoseModalInsertar()}>Nueva entrada</button></h1>
                <input type="button" className="btn btn-primary mb-5" value="Ver entradas" onClick={() => GetItems()} />
                {data !== [] ?
                    <>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Título</th>
                                    <th>Contenido</th>
                                    <th>Imagen</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map(item => (
                                    <tr key={"blog" + item.id}>
                                        <td>{item.data().date}</td>
                                        <td>{item.data().title}</td>
                                        <td>{item.data().content}</td>
                                        <td><img width="100" src={item.data().foto} alt="" className="image-fluid" /></td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Modal
                            show={modalInsertar}
                            onHide={() => OpenCLoseModalInsertar()}>
                            <Modal.Header closeButton>Nueva entrada</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Fecha</label>
                                    <br />
                                    <input className="form-control mb-3" type="date" name="date" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Título</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="title" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Contenido</label>
                                    <br />
                                    <textarea className="form-control mb-3" rows={5} name="content" onChange={HandleChange}></textarea>
                                    <br />
                                    <label className="form-label">Imagen</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="foto" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => Addblog()} className="btn btn-primary">Aceptar</button>
                                <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar entrada</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Fecha</label>
                                    <br />
                                    <input className="form-control mb-3" type="date" name="date" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Título</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.title} type="text" name="title" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Contenido</label>
                                    <br />
                                    <textarea className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.content} type="text" name="content" onChange={HandleChange}></textarea>
                                    <br />
                                    <label className="form-label">Imagen</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="foto" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => UpdateBlog()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar lider</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar la entrada {itemSeleccionado && itemSeleccionado.title}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteBlog()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="danger" />
                    </div>
                }
            </div>
        </>
    )
}
export default AddBlog