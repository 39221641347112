import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Form, Spinner, Table } from 'react-bootstrap';
import { collection, deleteDoc, doc, getDocs, setDoc } from '@firebase/firestore';
import { db, storage } from '../../firebase/FirebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { deleteObject, getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import { NombresRedes } from '../../utilities/NombresRedes';
import { FormatData } from '../../utilities/FormatData';

// UI del crud para el administrador que controla los productos
// que estan el las diferentes redes
// se muestra en /admin

const AddProducto = () => {
    // estados 
    //datos recibidos de la base de datos 
    const [data, setData] = useState([])
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);

    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({
        name: "",
        marca: "",
        monto: "",
        costo_peso_extra: 0,
        monto_subsidio: "",
        subsidio: "",
        cant_min: "",
        description: "",
        especificaciones: "",
        red: "",
        sub_red: ""
    })
    // manejador para las funciones editar y eliminar
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    //variable para manejar la foto de perfil
    const [imagen, setImagen] = useState(undefined)
    const HandleImageUpload = (archivos) => {
        setImagen(archivos[0])
    }

    //manejador para recibir los datos de entrada del usuario del modal insertar
    //cada que se escribe una letra se actualiza
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
        if (name === "subsidio") {
            const descuento = (itemSeleccionado.monto - value).toFixed(2);
            setitemSeleccionado((prevState) => ({
                ...prevState,
                monto_subsidio: descuento
            }))
        }
        if (name === "monto") {
            const descuento = (value - itemSeleccionado.subsidio).toFixed(2);
            setitemSeleccionado((prevState) => ({
                ...prevState,
                monto_subsidio: descuento
            }))
        }

    }
    const [red, setRed] = useState([])
    const HandleRedChange = async (e) => {
        // setSelectedRed(event)

        setitemSeleccionado((prevState) => ({
            ...prevState,
            red: e.target.value
        }))
        const ref = FormatData(e.target.value)
        const querySnapshot = await getDocs(collection(db, ref));
        setRed(querySnapshot.docs)
    }

    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)

    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        const querySnapshot = await getDocs(collection(db, "productos"));
        setData(querySnapshot.docs)
    }

    const VerifiacarInputs = () => {
        var inputError = ""
        if (imagen === undefined) {
            if (itemSeleccionado.foto === undefined)
                inputError += "Foto\n"
        }

        inputError += itemSeleccionado.name.length > 0 ? "" : "Nombre\n"
        inputError += itemSeleccionado.red.length > 0 ? "" : "Red\n"
        if (itemSeleccionado.sub_red !== undefined)
            inputError += itemSeleccionado.sub_red.length > 0 ? "" : "Sub red\n"
        inputError += itemSeleccionado.marca.length > 0 ? "" : "Marca\n"
        inputError += (isNaN(itemSeleccionado.monto) || itemSeleccionado.monto.length <= 0) ? "Monto\n" : ""
        inputError += (isNaN(itemSeleccionado.subsidio) || itemSeleccionado.subsidio.length <= 0) ? "Subsidio\n" : ""
        inputError += (isNaN(itemSeleccionado.monto_subsidio) || itemSeleccionado.monto_subsidio.length <= 0) ? "Monto con subsidio\n" : ""
        inputError += (isNaN(itemSeleccionado.cant_min) || itemSeleccionado.cant_min.length <= 0) ? "Cantidad mínima de compra\n" : ""
        inputError += itemSeleccionado.description.length > 0 ? "" : "Descripción\n"
        inputError += itemSeleccionado.especificaciones.length > 0 ? "" : "Especificaciones técnicas\n"

        return inputError
    }
    const PostProducto2 = async (id, ref) => {
        //console.log(itemSeleccionado)
        setImagen(undefined)
        await setDoc(doc(db, "productos", id),
            {
                name: itemSeleccionado.name,
                marca: itemSeleccionado.marca,
                monto: itemSeleccionado.monto,
                monto_subsidio: itemSeleccionado.monto_subsidio,
                costo_peso_extra: itemSeleccionado.costo_peso_extra,
                subsidio: itemSeleccionado.subsidio,
                cant_min: itemSeleccionado.cant_min,
                description: itemSeleccionado.description,
                especificaciones: itemSeleccionado.especificaciones,
                red: FormatData(itemSeleccionado.red) + "/" + itemSeleccionado.sub_red,
                foto: ref
            });
        GetItems()
        OpenCLoseModalInsertar()
        alert("¡Producto creado correctamente!");

    }
    //Metodo POST para enviar un nuevo item a la DB
    const PostProducto = () => {
        const errores = VerifiacarInputs()
        if (errores === "") {
            const temID = uuidv4();
            const storageRef = ref(storage, 'fotos_productos/' + temID);
            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, imagen).then((snapshot) => {
                getDownloadURL(storageRef).then(ref => {

                    PostProducto2(temID, ref)

                }).catch((error) => {
                    const errorMessage = error.message;
                    alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                    // ..
                });
            })

        }
        else
            alert("Completa los campos\n" + errores)
    }

    //Metodo PUT para editar una fila de la db
    const EditProducto = () => {
        const errores = VerifiacarInputs()
        if (errores === "") {

            if (imagen !== undefined) {
                const storageRef = ref(storage, 'fotos_productos/' + editItemSeleccionado.id);
                // 'file' comes from the Blob or File API
                uploadBytes(storageRef, imagen).then((snapshot) => {
                    getDownloadURL(storageRef).then(ref => {

                        EditProducto2(editItemSeleccionado.id, ref)

                    }).catch((error) => {
                        const errorMessage = error.message;
                        alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                        // ..
                    });
                })
            } else {
                EditProducto2(editItemSeleccionado.id, itemSeleccionado.foto)
            }


        }
        else
            alert("Completa los campos\n" + errores)
    }
    const EditProducto2 = async (id, ref) => {
        setImagen(undefined)
        const redAux = itemSeleccionado.sub_red !== undefined ? itemSeleccionado.red + "/" + itemSeleccionado.sub_red : itemSeleccionado.red
        await setDoc(doc(db, "productos", id),
            {
                name: itemSeleccionado.name,
                marca: itemSeleccionado.marca,
                monto: itemSeleccionado.monto,
                costo_peso_extra:itemSeleccionado.costo_peso_extra,
                monto_subsidio: itemSeleccionado.monto_subsidio,
                subsidio: itemSeleccionado.subsidio,
                cant_min: itemSeleccionado.cant_min,
                description: itemSeleccionado.description,
                especificaciones: itemSeleccionado.especificaciones,
                red: redAux,
                foto: ref
            });
        GetItems()
        OpenCLoseModalEditar()
        alert("¡Producto editado correctamente!");
    }
    //borrar una fila de la db
    const DeleteProducto = async () => {
        await deleteDoc(doc(db, "productos", editItemSeleccionado.id));

        const desertRef = ref(storage, 'fotos_productos/' + editItemSeleccionado.id);

        deleteObject(desertRef).then(() => {
            GetItems()
            OpenCLoseModalEliminar()
            alert("¡Producto eliminado correctamente!")
        }).catch((error) => {
            alert("Ocurrió un error al eliminar \n inténtalo más tarde")
        });
        

    }
    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);

        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }

    //render el cual retorna la lista de la db
    //botones de editar y eliminar y modal de agregar una nuevo item del carrusel
    //NOTA: una cuadro de cada carrusel tiene 3 items
    //El modal para editar
    //el modal para eliminar
    //es decir un crud basico
    return (
        <>
            <div className="container text-center mb-5 border border-info border-2">
                <h1>Productos<button className="btn text-white bg-indigo m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo producto</button></h1>
                <input type="button" className="btn btn-primary mb-5" value="Ver productos" onClick={() => GetItems()} />
                {data !== [] ?
                    <>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="align-middle">
                                    <th>Foto</th>
                                    <th>Nombre</th>
                                    <th>Red</th>
                                    <th>Marca</th>
                                    <th>Costo por peso extra</th>
                                    <th>Monto</th>                                    
                                    <th>Subsidio</th>
                                    <th>Monto con subsidio</th>
                                    <th>Cantidad mínima de compra</th>
                                    <th>Descripción</th>
                                    <th>Especificaciones técnicas</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map(item => (
                                    <tr key={"producto" + item.id}>
                                        <td><img width="100" src={item.data().foto} alt="" className="image-fluid" /></td>
                                        <td>{item.data().name}</td>
                                        <td>{item.data().red}</td>
                                        <td>{item.data().marca}</td>
                                        <td>{item.data().costo_peso_extra}</td>
                                        <td>{item.data().monto}</td>
                                        <td>{item.data().subsidio}</td>
                                        <td>{item.data().monto_subsidio}</td>
                                        <td>{item.data().cant_min}</td>
                                        <td>{item.data().description}</td>
                                        <td>{item.data().especificaciones}</td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Modal
                            show={modalInsertar}
                            onHide={() => OpenCLoseModalInsertar()}>
                            <Modal.Header closeButton>Nuevo producto</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Foto</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="imagen" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                    <br />
                                    <label className="form-label">Nombre</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Red</label>
                                    <br />
                                    <Form.Select aria-label="Red" name="red" className="mb-5" onChange={HandleRedChange}>
                                        <option value="0" >Selecciona una red</option>
                                        <option value={NombresRedes.vivienda} >{NombresRedes.vivienda}</option>
                                        <option value={NombresRedes.salud} >{NombresRedes.salud}</option>
                                        <option value={NombresRedes.alimentacion} >{NombresRedes.alimentacion}</option>
                                        <option value={NombresRedes.iluminacion} >{NombresRedes.iluminacion}</option>
                                        <option value={NombresRedes.arte} >{NombresRedes.arte}</option>
                                        <option value={NombresRedes.deporte} >{NombresRedes.deporte}</option>
                                        <option value={NombresRedes.medioAmbiente} >{NombresRedes.medioAmbiente}</option>
                                        <option value={NombresRedes.educacion} >{NombresRedes.educacion}</option>
                                        <option value={NombresRedes.innovacion} >{NombresRedes.innovacion}</option>
                                        <option value={NombresRedes.seguridad} >{NombresRedes.seguridad}</option>
                                        <option value={NombresRedes.inclusion} >{NombresRedes.inclusion}</option>
                                    </Form.Select>
                                    <br />
                                    <label className="form-label">Sub red</label>
                                    <br />
                                    <Form.Select aria-label="Red" name="sub_red" className="mb-5" onChange={HandleChange}>
                                        <option value="0" >Selecciona una sub red</option>
                                        {red.map(red => (
                                            <option key={"red" + red.id} value={red.id} >{red.id}</option>
                                        ))}
                                    </Form.Select>
                                    <br />
                                    <label className="form-label">Marca</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="marca" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Costo por peso extra</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.costo_peso_extra} className="form-control mb-3" type="number" name="costo_peso_extra" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Monto</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="monto" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Subsidio</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="subsidio" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Monto con subsidio</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.monto_subsidio} readOnly type="number" name="monto_subsidio" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Cantidad mínima de compra</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="cant_min" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Descripción</label>
                                    <br />
                                    <textarea className="form-control mb-3" type="text" name="description" onChange={HandleChange}></textarea>
                                    <br />
                                    <label className="form-label">Especificaciones técnicas</label>
                                    <br />
                                    <textarea className="form-control mb-3" type="text" name="especificaciones" onChange={HandleChange}></textarea>


                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => PostProducto()} className="btn btn-primary">Aceptar</button>
                                <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar producto</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Foto</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="imagen" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                    <br />
                                    <label className="form-label">Nombre</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="name" value={itemSeleccionado && itemSeleccionado.name} onChange={HandleChange} ></input>
                                    <br />
                                    <label className="form-label">Red</label>
                                    <br />
                                    <Form.Select aria-label="Red" name="red" className="mb-5" onChange={HandleRedChange}>
                                        <option value="0" >Selecciona una red</option>
                                        <option value={NombresRedes.vivienda} >{NombresRedes.vivienda}</option>
                                        <option value={NombresRedes.salud} >{NombresRedes.salud}</option>
                                        <option value={NombresRedes.alimentacion} >{NombresRedes.alimentacion}</option>
                                        <option value={NombresRedes.iluminacion} >{NombresRedes.iluminacion}</option>
                                        <option value={NombresRedes.arte} >{NombresRedes.arte}</option>
                                        <option value={NombresRedes.deporte} >{NombresRedes.deporte}</option>
                                        <option value={NombresRedes.medioAmbiente} >{NombresRedes.medioAmbiente}</option>
                                        <option value={NombresRedes.educacion} >{NombresRedes.educacion}</option>
                                        <option value={NombresRedes.innovacion} >{NombresRedes.innovacion}</option>
                                        <option value={NombresRedes.seguridad} >{NombresRedes.seguridad}</option>
                                        <option value={NombresRedes.inclusion} >{NombresRedes.inclusion}</option>
                                    </Form.Select>
                                    <br />
                                    <label className="form-label">Sub red</label>
                                    <br />
                                    <Form.Select aria-label="Red" name="sub_red" className="mb-5" onChange={HandleChange}>
                                        <option value="0" >Selecciona una sub red</option>
                                        {red.map(red => (
                                            <option key={"red" + red.id} value={red.id} >{red.id}</option>
                                        ))}
                                    </Form.Select>
                                    <br />
                                    <label className="form-label">Marca</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="marca" value={itemSeleccionado && itemSeleccionado.marca} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Costo por peso extra</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="costo_peso_extra" value={itemSeleccionado && itemSeleccionado.costo_peso_extra} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Monto</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="monto" value={itemSeleccionado && itemSeleccionado.monto} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Subsidio</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="subsidio" value={itemSeleccionado && itemSeleccionado.subsidio} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Monto con subsidio</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" readOnly name="montoSubsidio" value={itemSeleccionado && itemSeleccionado.monto_subsidio} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Cantidad mínima de compra</label>
                                    <br />
                                    <input className="form-control mb-3" type="number" name="cantidadMinima" value={itemSeleccionado && itemSeleccionado.cant_min} onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Descripción</label>
                                    <br />
                                    <textarea className="form-control mb-3" type="text" name="descripcion" value={itemSeleccionado && itemSeleccionado.description} onChange={HandleChange}></textarea>
                                    <br />
                                    <label className="form-label">Especificaciones técnicas</label>
                                    <br />
                                    <textarea className="form-control mb-3" type="text" name="especificaciones" value={itemSeleccionado && itemSeleccionado.especificaciones} onChange={HandleChange}></textarea>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => EditProducto()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar producto</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar el producto {editItemSeleccionado && editItemSeleccionado.data().name}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteProducto()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="success" />
                    </div>
                }
            </div>

        </>
    )
}

export default AddProducto