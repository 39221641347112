// normalizacion de los nombres de los nombres de las redes
import logo1 from '../../../assets/Beneficios/1.png'
import logo2 from '../../../assets/Beneficios/2.png'
import logo3 from '../../../assets/Beneficios/3.png'
import logo4 from '../../../assets/Beneficios/4.png'
import logo5 from '../../../assets/Beneficios/5.png'
import logo6 from '../../../assets/Beneficios/6.png'
import logo7 from '../../../assets/Beneficios/7.png'
import logo8 from '../../../assets/Beneficios/8.png'
import logo9 from '../../../assets/Beneficios/9.png'
import logo10 from '../../../assets/Beneficios/10.png'
import logo11 from '../../../assets/Beneficios/11.png'
import logo12 from '../../../assets/Beneficios/12.png'
import logo13 from '../../../assets/Beneficios/13.png'
import logo14 from '../../../assets/Beneficios/14.png'
import logo15 from '../../../assets/Beneficios/15.png'
import logo16 from '../../../assets/Beneficios/16.png'
import logo17 from '../../../assets/Beneficios/17.png'
import logo18 from '../../../assets/Beneficios/18.png'
import { Dropdown, DropdownButton} from 'react-bootstrap'

// Formulario con los logos que se pueden escojer para el administrador
// para escojer un beneficio
// esta componente se debe actualizar a la par de ShowLogosBeneficios.js 
const DropdownBeneficiosLogos = ({HandleLogoChange}) => {

    return (
        <DropdownButton id = "LogoBeneficiosArray" variant="primary w-100" title="Logo" onSelect={HandleLogoChange}>
            <Dropdown.Item className='d-inline' eventKey={1} ><img alt="..." src={logo1} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={2} ><img alt="..." src={logo2} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={3} ><img alt="..." src={logo3} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={4} ><img alt="..." src={logo4} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={5} ><img alt="..." src={logo5} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={6} ><img alt="..." src={logo6} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={7} ><img alt="..." src={logo7} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={8} ><img alt="..." src={logo8} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={9} ><img alt="..." src={logo9} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={10} ><img alt="..." src={logo10} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={11} ><img alt="..." src={logo11} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={12} ><img alt="..." src={logo12} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={13} ><img alt="..." src={logo13} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={14} ><img alt="..." src={logo14} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={15} ><img alt="..." src={logo15} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={16} ><img alt="..." src={logo16} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={17} ><img alt="..." src={logo17} /></Dropdown.Item>
            <Dropdown.Item className='d-inline' eventKey={18} ><img alt="..." src={logo18} /></Dropdown.Item>
        </DropdownButton>
    )
}

export default DropdownBeneficiosLogos