import '../../styles/Redes.css'
import React, { useEffect } from "react";
import '../../styles/Redes.css'
import NavBarRedes from "../../components/Redes/NavBarRedes";
import LoginComponent from '../../components/Redes/LoginComponent';
import SeleccionarRed from '../../components/Redes/SeleccionarRed';
import { useContext } from 'react';
import UserContext from '../../components/user/UserContext';
import FooterRedes from '../../components/Redes/FooterRedes';
//Pantalla principal del login
//hace verificacion de credenciales
// se accede como /redes

const Redes = () => {
    const { user } = useContext(UserContext)

    useEffect(()=>{
        window.scrollTo(0,0);
        //console.log(cookies.user)
        //console.log(user)
        
      },[])

    return (
        <>
            {user === undefined ?
                <>
                    <LoginComponent />
                </>
                :
                <>
                    <NavBarRedes/>
                    <SeleccionarRed />
                    <FooterRedes />

                </>
            }
        </>
    )
}

export default Redes