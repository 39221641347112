import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// Tarjeta que se muestra en el offcanvas de la caansta del usuario
// ina imagen a la izquierda e información a la derecha
const CarritoCard = (props) => {

    return (
        <>
            <Container className="mt-3">
                <Card className="shadow-sm">
                    <span className="position-absolute top-0 start-50 shadow translate-middle p-2 button-bg border border-light rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                    </span>
                    <Row xs={2} md={2} className="g-5">
                        <Col className="text-center ">
                            <img className="img-fluid w-75 m-4 rounded-circle" src={props.item.data.foto} alt="..." />
                        </Col>
                        <Col className="text-center">
                            <div className="p-4">
                                <p className = "fw-bold">{props.item.data.name}</p>
                                <p>{props.item.data.cantidad} unidades</p>
                                <p >${props.item.data.total}</p>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}

export default CarritoCard