import React, { useContext, useEffect} from "react";
import AddProducto from "../../components/Redes/Admin/AddProducto";
import AddSubRed from "../../components/Redes/Admin/AddSubRed";
import AddUser from "../../components/Redes/Admin/AddUser";
import AllMovimientos from "../../components/Redes/Admin/AllMovimientos";
import BeneficiosCRUD from "../../components/Redes/Admin/BeneficiosCRUD";
import AddBlog from "../../components/Redes/Admin/Blog";
import NavBarAdmin from "../../components/Redes/Admin/NavBarAdmin";
import FooterRedes from "../../components/Redes/FooterRedes";
import LoginAdminComponent from "../../components/Redes/LoginAdminComponent";
import UserContext from "../../components/user/UserContext";

// Pagina que se muestra en /admin
// muestra todas las finciones que puede tener un administrador
// 

const AdminDashBoard = () => {
    //cambiar user por admin
    const { admin } = useContext(UserContext)
    useEffect(()=>{
        window.scrollTo(0,0);
      },[])
    return (
        <>
            {admin === undefined ?
                <>
                    <LoginAdminComponent />
                </>
                :
                <>
                    <NavBarAdmin />
                    <div className="py-4"></div>
                    <AllMovimientos />
                    <AddUser />
                    <AddSubRed />
                    <AddProducto />
                    <AddBlog/>
                    <BeneficiosCRUD/>
                    <FooterRedes />
                </>
            }
        </>
    )
}
export default AdminDashBoard