import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Dropdown, DropdownButton, Form, Spinner, Table } from 'react-bootstrap';
import { collection, deleteDoc, doc, getDocs, setDoc } from '@firebase/firestore';
import { db } from '../../firebase/FirebaseConfig';
import { NombresRedes } from '../../utilities/NombresRedes';
import { FormatData } from '../../utilities/FormatData';

// UI del crud para el administrador que controla las sub redes
// que estan el las diferentes redes
// se muestra en /admin

const AddSubRed = () => {
    // estados 
    //datos recibidos de la base de datos 
    const [data, setData] = useState([])
    const [selectedRed, setSelectedRed] = useState("")
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);

    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({
        red: "",
        subRed: ""
    })

    //manejador para recibir los datos de entrada del usuario del modal insertar
    //cada que se escribe una letra se actualiza
    const HandleChange = e => {
        const { name, value } = e.target
        //console.log(itemSeleccionado)
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    //get en cuanto inicia la pagian
    useEffect(() => {
    }, [])

    //Metodo get para obtener los items de la DB
    const GetItems = async (event) => {
        setSelectedRed(event)
        const ref = FormatData(event)
        const querySnapshot = await getDocs(collection(db, ref));
        setData(querySnapshot.docs)
    }

    //Metodo POST para enviar un nuevo item a la DB
    const AddSubRed = async () => {
        if ((itemSeleccionado.subRed !== "" && itemSeleccionado.subRed !== undefined) 
        && (itemSeleccionado.red !== "" && itemSeleccionado.red !== undefined)) {
            // console.log(itemSeleccionado)
            await setDoc(doc(db, FormatData(itemSeleccionado.red), itemSeleccionado.subRed), {});
            OpenCLoseModalInsertar()
            GetItems(selectedRed)
            alert("¡Sub red creada correctamente!");
        }
        else
            alert("Completa todos los campos")



    }

    //borrar una fila de la db
    const DeleteSubRed = async () => {
        await deleteDoc(doc(db, FormatData(selectedRed), itemSeleccionado.id));
        OpenCLoseModalEliminar()
        alert("¡Sub red eliminada correctamente!");
        GetItems(selectedRed)

    }
    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item) => {
        setitemSeleccionado(item);
        //console.log(item)
        OpenCLoseModalEliminar()
    }

    //render el cual retorna la lista de la db
    //botones de editar y eliminar y modal de agregar una nuevo item del carrusel
    //NOTA: una cuadro de cada carrusel tiene 3 items
    //El modal para editar
    //el modal para eliminar
    //es decir un crud basico
    return (
        <>
            <div className="container text-center">
                <h1>Sub redes<button className="btn text-white bg-indigo m-5" onClick={() => OpenCLoseModalInsertar()}>Nueva sub red</button></h1>
                <DropdownButton className="mb-5" variant="primary" title="Ver sub redes" onSelect={(e) => GetItems(e)}>
                    <Dropdown.Item eventKey={NombresRedes.vivienda} >{NombresRedes.vivienda}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.salud} >{NombresRedes.salud}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.alimentacion} >{NombresRedes.alimentacion}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.iluminacion} >{NombresRedes.iluminacion}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.arte} >{NombresRedes.arte}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.deporte} >{NombresRedes.deporte}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.medioAmbiente} >{NombresRedes.medioAmbiente}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.educacion} >{NombresRedes.educacion}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.innovacion} >{NombresRedes.innovacion}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.seguridad} >{NombresRedes.seguridad}</Dropdown.Item>
                    <Dropdown.Item eventKey={NombresRedes.inclusion} >{NombresRedes.inclusion}</Dropdown.Item>
                </DropdownButton>
                {data !== [] ?
                    <>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Red</th>
                                    <th>Sub red</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr key={"subRed" + item.id}>
                                        <td>{selectedRed}</td>
                                        <td>{item.id}</td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item)}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Modal
                            show={modalInsertar}
                            onHide={() => OpenCLoseModalInsertar()}>
                            <Modal.Header closeButton>Nueva sub red</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <Form.Select aria-label="Red" name="red" className="mb-5" onChange={HandleChange}>
                                        <option value="0" >Selecciona una red</option>
                                        <option value={NombresRedes.vivienda} >{NombresRedes.vivienda}</option>
                                        <option value={NombresRedes.salud} >{NombresRedes.salud}</option>
                                        <option value={NombresRedes.alimentacion} >{NombresRedes.alimentacion}</option>
                                        <option value={NombresRedes.iluminacion} >{NombresRedes.iluminacion}</option>
                                        <option value={NombresRedes.arte} >{NombresRedes.arte}</option>
                                        <option value={NombresRedes.deporte} >{NombresRedes.deporte}</option>
                                        <option value={NombresRedes.medioAmbiente} >{NombresRedes.medioAmbiente}</option>
                                        <option value={NombresRedes.educacion} >{NombresRedes.educacion}</option>
                                        <option value={NombresRedes.innovacion} >{NombresRedes.innovacion}</option>
                                        <option value={NombresRedes.seguridad} >{NombresRedes.seguridad}</option>
                                        <option value={NombresRedes.inclusion} >{NombresRedes.inclusion}</option>
                                    </Form.Select>
                                    <label className="form-label">Sub red</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="subRed" onChange={HandleChange}></input>

                                    <br />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => AddSubRed()} className="btn btn-primary">Aceptar</button>
                                <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar sub red</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar la sub red <strong>{itemSeleccionado && itemSeleccionado.id}</strong>?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteSubRed()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="success" />
                    </div>
                }
            </div>

        </>
    )
}

export default AddSubRed