import React, { useContext, useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import CanastaDetallesCard from "../CanastaDetallesCard";
import { StatusCompra } from "../../user/StatusCompra";
import UserContext from "../../user/UserContext";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
// Primera vista de la genstion de canasta
// que se despliega en /canasta
// Muestra los productos a detalle que el lider 
// tiene en su canasta

const CanastaView1 = (props) => {

    const { carrito, user } = useContext(UserContext)
    const [show, SetShow] = useState(false)

    const HandleClose = () => {

        SetShow(!show)

    }

    const CambiarEstado = async () => {
        const total = (parseFloat(carrito.total) + parseFloat(carrito.data.costo_envio) + parseFloat(carrito.costo_peso_extra)).toFixed(2)
        if (total >= 2000) {
            await updateDoc(doc(db, "ordenes", carrito.id), {
                status: StatusCompra.enRevision,
                costo_peso_extra: carrito.costo_peso_extra,
                monto: total,
                date: Timestamp.fromDate(new Date())
            });
        } else {
            alert("Para poder proceder al pago debes hacer una compra de mínimo $2000, te invitamos a seguir revisando nuestros productos")
        }

    }
    const HandleVentana = () => {

        CambiarEstado();

        HandleClose();
    }
    return (
        <>
            <Container className="text-center mt-5">
                <h2>Gestión de mi canasta</h2>
            </Container>

            <Container>
                <Container className="my-5 w-75 position-relative">
                    <div className="progress" style={{ height: "5px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "0%" }} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span style={{ left: "0%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        1
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "33%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        2
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "66%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        3
                        <br />

                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "100%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        4
                        <span className="visually-hidden">unread messages</span>
                    </span>

                </Container>
            </Container>
            <Container className="mt-5 text-center">
                <h2>Confirma tus productos</h2>
            </Container>
            <Container className="mt-5 text-center">

                <Row className="">
                    <Col sm={9}>
                        <Container>
                            <p>Dirección de entrega:  <span> {user.data.address}</span></p>
                            {carrito.productos.map((item, idx) => (

                                <CanastaDetallesCard key={"canastaI" + idx} producto={item} />
                            ))}

                        </Container>

                    </Col>
                    <Col className="" sm={3}>
                        <h5>Resumen</h5>

                        <hr className="divider" />
                        <Row xs={1} md={2} className="">

                            <Col className="text-start">
                                <p>Subtotal: </p>
                            </Col>
                            <Col className="text-end">
                                <p>${carrito.total}</p>
                            </Col>
                        </Row>
                        <hr className="divider" />

                        <Row xs={1} md={2} className="">

                            <Col className="text-start">
                                <p>Transporte: </p>
                            </Col>
                            <Col className="text-end">
                                <p>${parseFloat(carrito.data.costo_envio).toFixed(2)}</p>
                            </Col>
                        </Row>
                        <hr className="divider" />
                        {carrito.costo_peso_extra > 0
                            && <><Row xs={1} md={2} className="">

                            <Col className="text-start">
                                <p>Costo por peso extra:</p>
                            </Col>
                            <Col className="text-end">
                                <p>${parseFloat(carrito.costo_peso_extra).toFixed(2)}</p>
                            </Col>
                        </Row>
                        <hr className="divider" /></>}

                        <Row xs={1} md={2} className="">

                            <Col className="text-start">
                                <p>Total: </p>
                            </Col>
                            <Col className="text-end">
                                <p>${(parseFloat(carrito.total) + parseFloat(carrito.data.costo_envio) + parseFloat(carrito.costo_peso_extra)).toFixed(2)}</p>
                            </Col>
                        </Row>

                        <input type="button" value="Confirmar pedido" onClick={() => HandleClose()} className="btn my-4 text-white bg-indigo w-100"></input>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={HandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar pedido</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>¿Desea proceder al pago de todos los productos dentro de su canasta?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => HandleClose()}>Volver a revisar</Button>
                    <input onClick={() => HandleVentana()} type="button" className="btn text-white bg-indigo" value="Continuar"></input>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CanastaView1