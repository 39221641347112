import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../user/UserContext";

// tarjeta que muestra una vista rapida a los productos
// se muestra en la pantalla "RedDeXCategoria"

const ProductoCard = (props) => {

    const { setSelectedProducto } = useContext(UserContext);

    const selectProduct = () =>{
        
        setSelectedProducto(props.producto)
    }
    return (
        <>
            <Link onClick={selectProduct} to="/producto" className="text-dark text-decoration-none">
                <Card className="shadow mb-4">
                    <Card.Img variant="top" src={props.producto.data().foto} />
                    <Card.Body>
                        <Card.Title>{props.producto.data().name}</Card.Title>
                    </Card.Body>
                </Card>
            </Link>
        </>
    )
}

export default ProductoCard