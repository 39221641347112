import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from '@firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import axios from 'axios';
import emailjs from 'emailjs-com';
import md5 from 'js-md5';
import React, { useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from '../../firebase/FirebaseConfig';
import { StatusCompra } from '../../user/StatusCompra';
import { MailerConfig } from '../../utilities/MailerConfig';
import { RandomPassword } from '../../utilities/RandomPassword';
// UI del crud para el administrador que controla los lideres
// se muestra en /admin

const AddUser = () => {

    //mail regex
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //curp regex
    const curpformat = /^([A-Z&]|[a-z&]{1})([AEIOUX]|[aeioux]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9]|[A-Z&]|[a-z&])[0-9]$/gm
    // estados 
    //datos recibidos de la base de datos 
    const [data, setData] = useState([])
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);

    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({
        address: "",
        cp: "",
        curp: "",
        email: "",
        institution: "",
        name: "",
        phone: "",
        profile_pic: undefined
    })
    // manejador para las funciones editar y eliminar
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    //manejador para recibir los datos de entrada del usuario del modal insertar
    //cada que se escribe una letra se actualiza
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        const querySnapshot = await getDocs(collection(db, "users"));
        setData(querySnapshot.docs)

    }

    const VerifiacarInputs = () => {
        var inputError = ""
        if (imagen === undefined) {
            if (itemSeleccionado.profile_pic === undefined)
                inputError += "Foto de perfil\n"
        }
        inputError += itemSeleccionado.name.length > 0 ? "" : "Nombre\n"
        if (itemSeleccionado.email.length > 0) {
            if (!itemSeleccionado.email.match(mailformat))
                inputError += "Email no válido \n"
        }
        else
            inputError += "Email\n"

        if (itemSeleccionado.curp.length > 0) {
            if (!itemSeleccionado.curp.match(curpformat))
                inputError += "CURP no válido \n"
        }
        else
            inputError += "CURP\n"
        inputError += itemSeleccionado.cp.length === 5 ? "" : "CP\n"
        inputError += itemSeleccionado.address.length > 0 ? "" : "Dirección\n"
        inputError += itemSeleccionado.phone.length === 10 ? "" : "Teléfono\n"
        inputError += itemSeleccionado.institution.length > 0 ? "" : "Institución\n"

        return inputError
    }
    //almacenar datos en la base de datos

    const AddLider2 = async (ref, user, pass, envioPrice) => {
        setImagen(undefined)
        const temID = uuidv4();
        await setDoc(doc(db, "users", user), {
            current_orden: temID,
            costo_envio: envioPrice,
            cp: itemSeleccionado.cp,
            address: itemSeleccionado.address,
            password: md5(pass),
            curp: itemSeleccionado.curp,
            email: itemSeleccionado.email,
            institution: itemSeleccionado.institution,
            name: itemSeleccionado.name,
            phone: itemSeleccionado.phone,
            profile_pic: ref
        }).catch((error) => {
            const errorMessage = error.message;
            alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
            // ..
        });
        CreateNewOrder(temID)
        GetItems()

    }
    const CreateNewOrder = async (id) => {
        await setDoc(doc(db, "ordenes", id), {
            comment: "",
            date: Timestamp.fromDate(new Date()),
            fotoPago: "",
            monto: 0,
            costo_envio: 0,
            costo_peso_extra: 0,
            user: itemSeleccionado.email,
            status: StatusCompra.comprando
        })
            .catch((error) => {
                const errorMessage = error.message;
                alert("Error: " + errorMessage + "\nError al crear nueva orden para este usuario")
                // ..
            });
        //CreateNewOrder2(id)
    }
    // const CreateNewOrder2 = async (id) => {

    //     await addDoc(collection(db, "ordenes/" + id + "/productos"), {})
    //         .catch((error) => {
    //             const errorMessage = error.message;
    //             alert("Error: " + errorMessage + "\nError al crear nueva orden para este usuario")
    //             // ..
    //         });

    // }
    const AddLider3 = (temPassword, envioPrice) => {
        const storageRef = ref(storage, 'fotos_de_perfil_lideres/' + itemSeleccionado.email);
        ////////////////////////
        //subir foto de perfil//
        ////////////////////////
        uploadBytes(storageRef, imagen).then((snapshot) => {
            getDownloadURL(storageRef).then(ref => {
                AddLider2(ref, itemSeleccionado.email, temPassword, envioPrice)
                OpenCLoseModalInsertar()
            }).catch((error) => {
                const errorMessage = error.message;
                alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                // ..
            });
        }).catch((error) => {
            const errorMessage = error.message;
            alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
            // ..
        });
    }
    //Metodo POST para enviar un nuevo item a la DB
    const AddLider = async () => {

        const userInputData = VerifiacarInputs()

        // const userInputData = "1"
        if (userInputData === "") {
            ////////////////////////////////////
            //verificar si el correo no existe//
            ////////////////////////////////////
            const docRef = doc(db, "users", itemSeleccionado.email);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                alert("Este correo electrónico ya existe")
            } else {

                //create new user 
                // console.log(auth)
                const temPassword = RandomPassword()
                /////////////////
                // enviar email//
                /////////////////
                emailjs.init(MailerConfig.userID);
                emailjs.send(MailerConfig.mailServiceID, MailerConfig.templateID
                    , { name: itemSeleccionado.name, password: temPassword, email: itemSeleccionado.email }).then(res => {
                        alert("se envio un correo a "
                            + itemSeleccionado.email + " con sus credenciales")

                        axios({
                            method: 'GET',
                            url: 'https://codigo-postales-mexico-gratis.p.rapidapi.com/code_postal/consulta/cp.php',
                            params: { cp: itemSeleccionado.cp },
                            headers: {
                                'x-rapidapi-host': 'codigo-postales-mexico-gratis.p.rapidapi.com',
                                'x-rapidapi-key': 'f771d0b99emsh71306b117a0bd08p1717b5jsn295e99f93ba5'
                            }
                        }).then(response => {

                            if (response.data.response.municipio !== "Uruapan") {
                                AddLider3(temPassword, 200)
                            }

                            else {
                                AddLider3(temPassword, 0)
                            }

                        }).catch(error => {
                            alert("Error en el código postal API, por favor contacta a tu programador");
                            AddLider3(temPassword, 0)
                        })


                    }).catch(error => {
                        const errorMessage = error.message;
                        alert("Error de mail: " + errorMessage + "\ninténtalo de nuevo más tarde")
                    })
            }

        }
        else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }

    }

    //Metodo PUT para editar una fila de la db
    const UpdateLider = () => {

        const errores = VerifiacarInputs()
        if (errores === "") {

            if (imagen !== undefined) {
                const storageRef = ref(storage, 'fotos_de_perfil_lideres/' + editItemSeleccionado.id);
                // 'file' comes from the Blob or File API
                uploadBytes(storageRef, imagen).then((snapshot) => {
                    getDownloadURL(storageRef).then(ref => {

                        UpdateLider2(editItemSeleccionado.id, ref)

                    }).catch((error) => {
                        const errorMessage = error.message;
                        alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                        // ..
                    });
                })
            } else {
                UpdateLider2(editItemSeleccionado.id, itemSeleccionado.profile_pic)
            }


        }
        else
            alert("Completa los campos\n" + errores)
    }
    const UpdateLider2 = async (user, ref) => {
        setImagen(undefined)
        await setDoc(doc(db, "users", user), {
            cp: itemSeleccionado.cp,
            address: itemSeleccionado.address,
            password: itemSeleccionado.password,
            curp: itemSeleccionado.curp,
            email: itemSeleccionado.email,
            institution: itemSeleccionado.institution,
            name: itemSeleccionado.name,
            phone: itemSeleccionado.phone,
            profile_pic: ref
        }).catch((error) => {
            const errorMessage = error.message;
            alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
            // ..
        });

        GetItems()
        OpenCLoseModalEditar()
        alert("¡Producto editado correctamente!");
    }
    //borrar una fila de la db
    const DeleteLider = async () => {
        await deleteDoc(doc(db, "users", editItemSeleccionado.id));

        const desertRef = ref(storage, 'fotos_de_perfil_lideres/' + editItemSeleccionado.id);

        deleteObject(desertRef).then(() => {
            GetItems()
            OpenCLoseModalEliminar()
            alert("¡Usuario eliminado correctamente!")
        }).catch((error) => {
            alert("Ocurrió un error al eliminar \n inténtalo más tarde")
        });
    }
    //variable para manejar la foto de perfil
    const [imagen, setImagen] = useState(undefined)
    const HandleImageUpload = (archivos) => {
        setImagen(archivos[0])
    }

    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);
        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }
    //render el cual retorna la lista de la db
    //botones de editar y eliminar y modal de agregar una nuevo item del carrusel
    //NOTA: una cuadro de cada carrusel tiene 3 items
    //El modal para editar
    //el modal para eliminar
    //es decir un crud basico
    return (
        <>
            <div className="container text-center mt-5 border border-info border-2">
                <h1>Lideres<button className="btn text-white bg-indigo m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo lider</button></h1>
                <input type="button" className="btn btn-primary mb-5" value="Ver lideres" onClick={() => GetItems()} />
                {data.length !== [] ?
                    <>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Foto de perfil</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>CURP</th>
                                    <th>CP</th>
                                    <th>Dirección</th>
                                    <th>Teléfono</th>
                                    <th>Institución</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map(item => (
                                    <tr key={"lider" + item.id}>
                                        <td><img width="100" src={item.data().profile_pic} alt="" className="image-fluid" /></td>
                                        <td>{item.data().name}</td>
                                        <td>{item.data().email}</td>
                                        <td>{item.data().curp}</td>
                                        <td>{item.data().cp}</td>
                                        <td>{item.data().address}</td>
                                        <td>{item.data().phone}</td>
                                        <td>{item.data().institution}</td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Modal
                            show={modalInsertar}
                            onHide={() => OpenCLoseModalInsertar()}>
                            <Modal.Header closeButton>Nuevo lider</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Foto de perfil</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="imagen" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                    <br />
                                    <label className="form-label">Nombre</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Email</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="email" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">CURP</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">CP</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="cp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Dirección</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="address" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Teléfono</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="phone" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Institución</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="institution" onChange={HandleChange}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => AddLider()} className="btn btn-primary">Aceptar</button>
                                <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar lider</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Foto de perfil</label>
                                    <br />
                                    <input className="form-control mb-3" type="file" accept="image/png, image/jpeg" name="imagen" onChange={(e) => HandleImageUpload(e.target.files)}></input>
                                    <br />
                                    <label className="form-label">Nombre</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.name} name="name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Email (esto es un identificador, no puede ser modificado)</label>
                                    <br />
                                    <input className="form-control mb-3" readOnly type="text" value={itemSeleccionado && itemSeleccionado.email} name="email" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">CURP</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.curp} name="curp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">CP</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.cp} name="cp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Dirección</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.address} name="address" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Teléfono</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.phone} name="phone" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Institución</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" value={itemSeleccionado && itemSeleccionado.institution} name="institution" onChange={HandleChange}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => UpdateLider()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar lider</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar el lider {itemSeleccionado && itemSeleccionado.name}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteLider()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="success" />
                    </div>
                }
            </div>

        </>
    )
}

export default AddUser