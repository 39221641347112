import { collection, getDocs, query, where } from '@firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Badge, Card, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { db } from '../../components/firebase/FirebaseConfig'
import FooterRedes from '../../components/Redes/FooterRedes'
import NavBarRedes from '../../components/Redes/NavBarRedes'
import { StatusCompra } from '../../components/user/StatusCompra'
import UserContext from '../../components/user/UserContext'

// Muestra el perfil del usuario y sus movimientos
// se accede como /perfil
const RedesDashboard = () => {

    const { setStatusCompra, user } = useContext(UserContext);

    const ViewMovimiento = () => {

        setStatusCompra(StatusCompra.enEsperaDePago)

    }

    const [ordenes, setOrdenes] = useState(undefined)

    useEffect(() => {
        const GetMovimientos = async () => {
            const q = query(collection(db, "ordenes"), where("user", "==", user.id));
            const querySnapshot = await getDocs(q);
            setOrdenes(querySnapshot.docs)
            //console.log(user.id)
        }
        window.scrollTo(0, 0);
        GetMovimientos()
    }, [user])

    return (
        <>

            {user === undefined ?
                <>
                    {window.location.href = "/redes"}
                </>
                :
                <>
                    <NavBarRedes />
                    <Container className="mt-5">
                        <Card className="shadow">
                            <span className="position-absolute top-0 start-0 translate-middle p-2 button-bg border border-light rounded-circle">
                                <span className="visually-hidden">New alerts</span>
                            </span>
                            <Row xs={1} md={2} className="g-4">
                                <Col className="text-center">
                                    <img width="200" height="200" className=" m-4 rounded-circle" src={user.data.profile_pic} alt="..." />
                                </Col>
                                <Col className="">
                                    <div className="p-4 border-start">
                                        <h2>{user.data.name}</h2>
                                        <hr className="dropdown-divider" />
                                        <p><strong>Correo electrónico: </strong><span>{user.data.email}</span></p>
                                        <p><strong>Número de teléfono: </strong><span>{user.data.phone}</span></p>
                                        <p><strong>CURP: </strong><span>{user.data.curp}</span></p>
                                        <p><strong>Institución: </strong><span>{user.data.institution}</span></p>
                                        <p><strong>Código Postal: </strong><span>{user.data.cp}</span></p>
                                        <p><strong>Dirección: </strong><span>{user.data.address}</span></p>
                                        <hr className="dropdown-divider" />
                                    </div>


                                </Col>
                            </Row>
                        </Card>
                    </Container>

                    <Container className="my-5">
                        <Card className="shadow">
                            <span className="position-absolute top-0 start-0 translate-middle p-2 button-bg border border-light rounded-circle">
                                <span className="visually-hidden">New alerts</span>
                            </span>

                            <div className="p-4 text-center">
                                <h2>Movimientos</h2>
                                {ordenes === undefined ?
                                    <div className="text-center my-5 py-5">
                                        <Spinner animation="border" variant="danger" />
                                    </div>
                                    :

                                    <Table striped bordered hover responsive size="sm">
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Status</th>
                                                <th>Comentario</th>
                                                <th>Precio</th>
                                                <th>Productos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordenes.map((item, idx) => (
                                                <tr key={"orden" + idx}>
                                                    <td>{new Date(item.data().date.seconds * 1000).toLocaleDateString("es-MX")}</td>
                                                    <td>{item.data().status}</td>
                                                    <td>{item.data().comment}</td>
                                                    <td>{item.data().monto}</td>

                                                    <td>{idx === 0 && <Link to="/canasta"><Badge onClick={() => ViewMovimiento()} pill bg="bg-indigo" className="text-white bg-indigo">Ver</Badge></Link>}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>

                        </Card>
                    </Container>
                    <FooterRedes />
                </>
            }

        </>
    )
}

export default RedesDashboard