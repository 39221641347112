const AnimateComponent = (animation, transition, wrapper) => {
    const square = document.getElementById(animation);
    square.classList.remove(transition);

    // Create the observer, same as before:
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                square.classList.add(transition);
                return;
            }

            square.classList.remove(transition);
        });
    });
    observer.observe(document.getElementById(wrapper));

}

export {AnimateComponent}