import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../../styles/RedesCard.css'
import UserContext from "../user/UserContext";
import {FormatData} from '../utilities/FormatData'
// Tarjeta que muestra las Redes que tiene disponibles un lider
// se muestra en la pantalla Redes

const RedesCard = (props) => {

    const { setSelectedRed } = useContext(UserContext)

    const RedClicked = () => {
        setSelectedRed(FormatData(props.route))
        // console.log("clicked")
    }
    return (
        <>

            <Link onClick={RedClicked} to = "/lista-de-productos">

                <Card className="reducir text-white shadow ">
                    <div className="reducir">
                        <Card.Img src={props.img} className="rounded-3" alt="Card image" />
                    </div>
                    <Card.ImgOverlay className="text-center reducir m-0 p-0">
                        <div className={props.iconImg + " shadow position-absolute top-75 start-50 translate-middle p-5 rounded-circle"}>
                            <span className="visually-hidden ">New alerts</span>
                        </div>
                        <Card.Title style = {{backgroundColor:"white"}} className={" text-dark py-3 rounded-3"}>{props.title}</Card.Title>

                    </Card.ImgOverlay>
                </Card>
            </Link>
        </>

    )
}
export default RedesCard

