import { collection, getDocs, limit, orderBy, query } from "firebase/firestore"
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import logo from '../assets/logo.png'
import { db } from "../components/firebase/FirebaseConfig"
import BlogCard from '../components/general/BlogCard'
import Footer from '../components/general/footer'
import NavBar from '../components/general/NavBar'
import { AnimateComponent } from '../components/utilities/AnimateComponent'
// Pagina principal
// Busca dar un primer vistaso sobre lo que
// representa la asociación
// se accede como /

const Main = () => {

  const [data, setData] = useState(undefined)
  const GetBlog = async () => {
    const blogRef = collection(db, "blog");
    const q = query(blogRef, orderBy("date", "desc"), limit(3));
    const querySnapshot = await getDocs(q);
    setData(querySnapshot.docs)
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    AnimateComponent("animation1", 'erase-animation-transition', 'erase-animation-wrapper1')
    AnimateComponent("animation2", 'erase-animation-transition', 'erase-animation-wrapper2')
    GetBlog()
    AnimateComponent("animation3", 'erase-animation-transition', 'erase-animation-wrapper3')


  }, [])
  return (
    <>
      <NavBar />

      <div id="erase-animation-wrapper1" className="erase-animation-wrapper main-banner-image w-100  d-flex justify-content-center align-items-center">
        <div id="animation1" className="content text-center erase erase-animation">

          <img src={logo} alt="logo" className="img-fluid" />

        </div>
      </div>
      <div id="erase-animation-wrapper2" className=" erase-animation-wrapper container mt-5 d-grid gap-5 ">
        <div id="animation2" className="p-5 border erase erase-animation text-center">
          <h4>
            Comunicados
          </h4>
        </div>
      </div>
      <div id="erase-animation-wrapper3" className="erase-animation-wrapper">
      <Container id = "animation3" className='erase erase-animation'>
        {data !== undefined ?
          <Row xs={1} md={3} className="g-5 px-0 m-0 py-5">
            {data.map((item, idx) => (
              <Col key={"blog" + idx}>
                <BlogCard  item={item} />
              </Col>
            ))}
          </Row>
          :
          <div className="text-center mt-4">
            <Spinner animation="border" variant="danger" />
          </div>
        }

      </Container>
      </div>
      <Footer />
    </>)
}
export default Main