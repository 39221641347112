import React from "react";
import { Col, Container, Offcanvas, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import CarritoCard from "./CarritoCard";
// se abre cuando el usuario oprime el boton "mi canasta" en el nav bar
// redes, muestra los productos que el usuario tiene en el carrito/ canasta 
const CarritoOffCanvas = (props) => {

    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleOffCanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Mi canasta</Offcanvas.Title>
                </Offcanvas.Header>
                {props.carrito !== undefined ?

                    <Offcanvas.Body>
                        {props.carrito.productos.map(item => (

                            <CarritoCard key = {"carritoItem" + item.id} item= {item}/>
                        ))}


                    </Offcanvas.Body>

                    :
                    <div className="text-center my-5 py-5">
                        <Spinner animation="border" variant="danger" />
                    </div>
                }
                <div className="mx-4">
                    <Container>
                        <Row className="mt-2">
                            <Col className="text-stard">
                                Monto total:
                            </Col>
                            <Col className="text-end">
                                ${props.carrito && props.carrito.total}
                            </Col>
                        </Row>
                        <Link to="/canasta" className="btn my-4 text-white bg-indigo w-100"> Gestionar mi canasta</Link>
                    </Container>
                </div>

            </Offcanvas>
        </>
    )
}

export default CarritoOffCanvas